import { useState, useCallback, useEffect } from 'react';
import { bytesToSize, handleGraphQLErrors } from '../../../lib/utils';
import { useMutation } from '@apollo/client';
import { useLoanApplicationContext } from '../../../hooks';
import { logEvent } from '../../../lib/GAHelper';
import { UPLOAD_BANK_STATEMENT } from '../mutations';
import { pages } from '../../../lib/constants';

const useBankStatementUploaderForm = ({
  customForm,
  handleNext,
  formIndex,
}) => {
  const [uploadBankStatementError, setUploadBankStatementError] =
    useState(null);

  const {
    loanApplicationState,
    setLoanApplicationState,
    updateContextState,
    createApplicationTrace,
    application,
    applicationLoading,
    setCompletedStep,
    account,
    userLoading,
    getUserViewer,
  } = useLoanApplicationContext();

  const [mutate, { data, loading: uploadBankStatementLoading }] = useMutation(
    UPLOAD_BANK_STATEMENT,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        setUploadBankStatementError(
          errorMessage ||
            'Unable to upload file. Check file format and try again later.',
        );
      },
      onCompleted() {
        setCompletedStep('bankStatementUpload');
        if (customForm) {
          handleNext({
            variables: {
              applicationId: application.application?.id,
              data: { step: formIndex + 1, path: '', subStep: null },
            },
          });
        }
        return;
      },
    },
  );

  const uploadBankStatement = useCallback(
    (bankStatement, bankId, documentPassword, applicationId) => {
      if (!bankStatement) {
        setUploadBankStatementError('Please select a pdf file for upload');
        return;
      }

      const { validity, file } = bankStatement;
      validity &&
        mutate({
          variables: {
            file,
            password: documentPassword,
            bankId,
            applicationId,
          },
        });
    },
    [],
  );

  const [bankStatementDetails, setBankStatementDetails] = useState({});

  const handleDocumentPreview = useCallback(e => {
    const { validity, files } = e.target;
    const file = files[0];

    if (!file || file.size > 10000000 || file.type !== 'application/pdf') {
      setUploadBankStatementError(
        'Only .pdf files are allowed with size lower than 10MB. Please check the file format and try again.',
      );
      return;
    }

    setUploadBankStatementError('');
    setBankStatementDetails({
      fileName: file.name,
      fileSize: bytesToSize(file.size),
      bankStatement: { file, validity },
    });
  }, []);

  const handleUpload = useCallback(
    ({ statementPassword }) => {
      logEvent('Signup', 'Upload Bank Statement');
        uploadBankStatement(
        bankStatementDetails.bankStatement,
        account?.bankAccounts[0]?.bank?.id,
        statementPassword,
        application.application?.id,
      );
    },
    [bankStatementDetails, account, application],
  );

  useEffect(() => {
    if (uploadBankStatementError) {
      setTimeout(() => setUploadBankStatementError(''), 4500);
    }
  }, [uploadBankStatementError]);

  useEffect(() => {
    if (data) {
      const {
        uploadBankStatement: { filename },
      } = data;
      setLoanApplicationState(prevState => ({
        ...prevState,
        bankStatementFilename: filename,
      }));
    }
  }, [data]);

  useEffect(() => {
    getUserViewer()
    const { isFromOkraPage } = loanApplicationState;
    updateContextState('upload-bank-statement', {
      applicationId: application?.data?.application?.id,
      bankId: account?.bankAccounts[0]?.id,
      isFromOkraPage,
    });
  }, []);

  useEffect(() => {
    createApplicationTrace(
      pages.bankStatementUpload,
      'Navigated to Bank Statement Upload Screen',
    );
  }, []);

  return {
    applicationLoading,
    uploadBankStatementLoading,
    uploadBankStatementError,
    handleUpload,
    handleDocumentPreview,
    bankStatementDetails,
    userLoading,
  };
};

export default useBankStatementUploaderForm;
