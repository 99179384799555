import React, { useEffect } from 'react';
import { Switch, Redirect, useRouteMatch, useHistory } from 'react-router-dom';
import Notifications from 'react-notify-toast';
import SignupRoute from '../SignupRoute';
import { SignupProvider } from '../SignupContext';
import * as S from './styled';
import CreateNewAccount from '../CreateNewAccount';
import MinimumRequirements from '../MinimumRequirements';
import BvnVerification from '../BvnVerification';
import CustomerInfo from '../CustomerInfo';
import { useUserViewer } from '../../../hooks';

const Signup = ({ locationState, location }) => {
  const { path } = useRouteMatch();

  const history = useHistory();

  const { viewerData } = useUserViewer();

  const user = viewerData?.loaded?.viewer?.me;

  const auth = localStorage.getItem("Auth");

  useEffect(() => {
    if (!auth) {
      history.push('/v1/sign-up/create-account');
    }

    if (auth && !user?.bvnStatus?.bvn) {
      history.push('/v1/sign-up/bvn-verification');
    }

    if (auth && !user?.kycInformation && user?.bvnStatus?.bvn) {
      history.push('/v1/sign-up/customer-info');
    }
  }, [user?.bvnStatus?.bvn, user?.kycInformation]);

  return (
    <SignupProvider>
      <Notifications />
      <S.Progressbar pathname={location.pathname}>
        <div></div>
      </S.Progressbar>

      <Switch>
        <SignupRoute
          path={`${path}/create-account`}
          component={CreateNewAccount}
        />

        <SignupRoute
          path={`${path}/minimum-requirements`}
          component={MinimumRequirements}
        />
        <SignupRoute
          path={`${path}/bvn-verification`}
          component={BvnVerification}
        />

        <SignupRoute path={`${path}/customer-info`} component={CustomerInfo} />

        <Redirect
          to={{
            pathname: `${path}/create-account`,
            state: locationState,
          }}
        />
      </Switch>
    </SignupProvider>
  );
};

export default Signup;
